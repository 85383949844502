(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-event.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-event.js');
"use strict";


const BombenEvent = _ref => {
  let {
    description,
    isCancelled,
    children
  } = _ref;
  return React.createElement("div", {
    className: "retail-row-bet-event"
  }, React.createElement("div", {
    className: "retail-row-header f-medium"
  }, description), isCancelled && React.createElement("div", {
    className: "fc-red"
  }, "Inst\xE4lld, matchen r\xE4knas som vinst"), children);
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.BombenEvent', BombenEvent);

 })(window);