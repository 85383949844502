(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/fulltraff-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/fulltraff-result.js');

'use strict';

const {
  useSelector
} = ReactRedux;
const {
  ReactIcon
} = svs.ui;
const {
  helpers,
  FulltraffResultRow
} = svs.components.marketplaceRetailAdmin.rowResult;
const {
  getGameByActivatedDrawId
} = svs.components.marketplaceData.selectors.games;
const {
  sportProducts
} = svs.components.lbUtils;
const FulltraffResult = _ref => {
  let {
    activatedDrawId
  } = _ref;
  const activatedDraw = useSelector(state => getGameByActivatedDrawId(state, state.EditorSession.groupId, activatedDrawId));
  const events = useSelector(state => {
    if (activatedDraw && state.Draws) {
      return state.Draws.drawEvents[sportProducts.getProdName(activatedDraw.productId).toLowerCase()];
    }
    return [];
  });
  let uSigns = [];
  let isSingleRow = false;
  const wager = activatedDraw && activatedDraw.wager;
  if (wager && wager.rows) {
    const bets = wager ? Array.isArray(wager.bets) && wager.bets.filter(bet => bet.drawNum !== 0) : {};
    if (bets[0]) {
      uSigns = helpers.systemType.getUSigns(bets[0]);
      isSingleRow = helpers.systemType.isSingleRow(bets[0]);
    }
  }
  const rows = wager && wager.rows || [];
  const currentEvents = activatedDraw && events ? events[activatedDraw.drawNumber] : [];
  const renderEmptyView = () => React.createElement("div", {
    className: "loading-area padding"
  }, React.createElement("div", {
    className: "qa-info-text fc-grey-400 padding f-center"
  }, React.createElement(ReactIcon, {
    icon: "info",
    size: "200"
  }), React.createElement("span", {
    className: "margin-left-half"
  }, "Vi kan inte visa raden f\xF6r detta system")));

  const renderResults = () => React.createElement("div", {
    className: "fulltraff-retail-row-events",
    "data-testid": "fulltraff-result"
  }, React.createElement("div", {
    className: "retail-row-header f-left f-medium"
  }, "Matcher"), rows === null || rows === void 0 ? void 0 : rows.map((row, key) => {
    if (currentEvents !== null && currentEvents !== void 0 && currentEvents[key]) {
      return React.createElement(FulltraffResultRow, {
        event: currentEvents[key],
        index: key
        ,
        key: key,
        row: row,
        uSigns: uSigns
      });
    }
    return null;
  }));
  return React.createElement("div", {
    className: "retail-row-results notranslate"
  }, events && !isSingleRow ? renderResults() : renderEmptyView());
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.FulltraffResult', FulltraffResult);

 })(window);