(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/fulltraff-result-row.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/fulltraff-result-row.js');
'use strict';

const {
  BitFlag
} = svs.components.lbUtils.bitFlag;
const {
  ReactButtonBet
} = svs.ui;
const FulltraffResultRow = _ref => {
  let {
    event,
    index,
    row,
    uSigns
  } = _ref;
  const inputs = [...Array(8).keys()];
  const selectedInputs = BitFlag.parse(row);
  return React.createElement("div", null, React.createElement("div", null, React.createElement("span", {
    className: "event",
    key: event.eventNumber
  }, event.eventNumber, ".", ' ', event.eventDescription)), React.createElement("div", {
    className: "retail-row-bet f-center",
    style: {
      justifyContent: 'flex-start'
    }
  }, inputs.map(value => React.createElement(ReactButtonBet, {
    key: value,
    label: "".concat(value === 7 ? '7+' : value),
    selected: selectedInputs[value] === 1,
    size: "200",
    system: uSigns[index] === '1' ? 'U' : null
  }))));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.FulltraffResultRow', FulltraffResultRow);

 })(window);