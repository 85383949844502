(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/system-type.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/system-type.js');
"use strict";


function isReduceFree(bet) {
  if (typeof bet === 'undefined') {
    return false;
  }
  return bet.betProperties.find(prop => prop === 'REDUCERA_FRITT') === 'REDUCERA_FRITT';
}
function isSingleRow(bet) {
  return bet && bet.game1X2Boards && bet.game1X2Boards[0].systemType[0] === 'SROW';
}
function getUSigns(bet) {
  if (bet && bet.game1X2Boards && bet.game1X2Boards[0].systemType[0] === 'SROW') {
    return [];
  }
  return bet && bet.game1X2Boards && bet.game1X2Boards[1] ? bet.game1X2Boards[1].boardData : [];
}
function getMSigns(bet) {
  const mSigns = [];
  if (bet && bet.game1X2Boards) {
    bet.game1X2Boards[0].boardData.forEach(b => {
      mSigns.push(b.indexOf('M') > -1);
    });
  }
  return mSigns;
}
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.helpers.systemType', {
  isReduceFree,
  getMSigns,
  getUSigns,
  isSingleRow
});

 })(window);