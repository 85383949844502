(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/row-results.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/row-results.js');

'use strict';

const {
  useSelector
} = ReactRedux;
const {
  ReactIcon
} = svs.ui;
const {
  getProdName,
  resolveProductId,
  productIds
} = svs.components.lbUtils.sportProducts;
const {
  BombenRegularResult,
  BombenXpertResult,
  TipsenResult,
  ChallengeResult,
  helpers
} = svs.components.marketplaceRetailAdmin.rowResult;
const {
  SportkryssWrapper
} = svs.components.marketplaceShared.sportkryssWrapper;
const {
  games: {
    getGameByActivatedDrawId
  },
  draws: {
    getDraw,
    getForecast,
    getDrawEvents
  }
} = svs.components.marketplaceData.selectors;
const RowResults = _ref => {
  let {
    activatedDrawId
  } = _ref;
  const activatedDraw = useSelector(state => getGameByActivatedDrawId(state, state.EditorSession.groupId, activatedDrawId));
  const {
    productId,
    drawNumber,
    wager
  } = activatedDraw || {};
  const productName = productId ? getProdName(productId).toLowerCase() : null;
  const isBomben = productId && productId === productIds.BOMBEN;
  const isChallenge = productId && productId === productIds.CHALLENGE;
  const currentDraw = useSelector(state => drawNumber && productName ? getDraw(state, {
    drawNumber,
    productName
  }) : null);
  const events = useSelector(state => drawNumber && productName ? getDrawEvents(state, {
    drawNumber,
    productName
  }) : null);
  const forecast = useSelector(state => isBomben ? getForecast(state, productId, drawNumber) : null);
  const bombenData = Object.seal({
    eventMarks: [],
    isXPertenBomb: isBomben && (wager === null || wager === void 0 ? void 0 : wager.bets[0]) && Boolean(wager.bets[0].expertenBoardData),
    forecast: null,
    isPeriod: (currentDraw === null || currentDraw === void 0 ? void 0 : currentDraw.matchCount) === 1,
    periodHeader: null
  });
  if (isBomben) {
    if (events) {
      bombenData.eventMarks = bombenData.isXPertenBomb ? helpers.bombenXpertMarks(events, wager) : helpers.bombenMarks(events, wager);
    }
    if (forecast) {
      for (let i = 0; i < forecast.drawResults.length; ++i) {
        bombenData.eventMarks[i].isCancelled = forecast.drawResults[i].cancelled === 1;
      }
    }
    if (bombenData.isPeriod && currentDraw !== null && currentDraw !== void 0 && currentDraw.drawEvents) {
      bombenData.periodHeader = "".concat(currentDraw.drawEvents[0].matchParticipants[0].mediumName, " - ").concat(currentDraw.drawEvents[0].matchParticipants[1].mediumName);
    }
  }
  const tipsenData = Object.seal({
    mSigns: [],
    hasMSign: false,
    uSigns: [],
    isReduceFree: false,
    isSingleRow: false
  });
  if (!isBomben && Array.isArray(wager === null || wager === void 0 ? void 0 : wager.bets)) {
    const bets = wager.bets.filter(bet => bet.drawNum !== 0);
    if (bets[0]) {
      tipsenData.mSigns = helpers.systemType.getMSigns(bets[0]);
      tipsenData.hasMSign = tipsenData.mSigns.includes(true);
      tipsenData.uSigns = helpers.systemType.getUSigns(bets[0]);
      tipsenData.isReduceFree = helpers.systemType.isReduceFree(bets[0]);
      tipsenData.isSingleRow = helpers.systemType.isSingleRow(bets[0]);
    }
  }
  const percentages = !isBomben && wager !== null && wager !== void 0 && wager.rows ? helpers.getPercentages1X2PerRow(wager.rows) : [];
  const sportKryss = wager !== null && wager !== void 0 && wager.bets[1] && resolveProductId(wager === null || wager === void 0 ? void 0 : wager.bets[1].productId) === productIds.SPORTKRYSS ? wager === null || wager === void 0 ? void 0 : wager.bets[1] : null;
  const classname = isChallenge ? 'retail-row-results-challenge' : 'retail-row-results';
  const renderEmptyView = () => React.createElement("div", {
    className: "loading-area padding"
  }, React.createElement("div", {
    className: "qa-info-text fc-grey-400 padding f-center"
  }, React.createElement(ReactIcon, {
    icon: "info",
    size: "200"
  }), React.createElement("span", {
    className: "margin-left-half"
  }, "Vi kan inte visa raden f\xF6r detta system")));
  const renderResults = () => {
    if (isBomben) {
      if (bombenData.isXPertenBomb) {
        return React.createElement(BombenXpertResult, {
          bombenEventMarks: bombenData.eventMarks,
          isPeriod: bombenData.isPeriod,
          periodHeader: bombenData.periodHeader
        });
      }
      return React.createElement(BombenRegularResult, {
        bombenEventMarks: bombenData.eventMarks,
        isPeriod: bombenData.isPeriod,
        periodHeader: bombenData.periodHeader
      });
    }
    if (isChallenge) {
      return React.createElement(ChallengeResult, {
        events: events,
        percentages: percentages
      });
    }
    return React.createElement(TipsenResult, {
      events: events,
      hasMSign: tipsenData.hasMSign,
      isReduceFree: tipsenData.isReduceFree,
      mSigns: tipsenData.mSigns,
      percentages: percentages,
      uSigns: tipsenData.uSigns
    });
  };
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "".concat(classname, " notranslate margin-bottom-1")
  }, events && !tipsenData.isSingleRow ? renderResults() : renderEmptyView()), React.createElement("div", null, Boolean(sportKryss) && React.createElement("div", {
    className: "sport-kryss-wrapper-holder"
  }, React.createElement(SportkryssWrapper, {
    branding: "marketplace",
    sportKryssWager: sportKryss
  }))));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.RowResults', RowResults);

 })(window);