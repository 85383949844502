(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-xpert-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-xpert-result.js');
"use strict";


const {
  ReactButtonBet
} = svs.ui;
const {
  BombenEvent,
  BombenResultFrame
} = svs.components.marketplaceRetailAdmin.rowResult;
const BombenXpertResult = _ref => {
  let {
    isPeriod,
    periodHeader,
    bombenEventMarks
  } = _ref;
  return React.createElement(BombenResultFrame, {
    isPeriod: isPeriod,
    periodHeader: periodHeader
  }, bombenEventMarks.map(event => React.createElement(BombenEvent, {
    description: event.eventDescription,
    isCancelled: event.isCancelled,
    key: event.eventNumber
  }, React.createElement("div", {
    className: "grid-row"
  }, React.createElement("div", {
    className: "col-md-12"
  }, React.createElement("div", {
    className: "padding-h-2 padding-v-1 col-xs-3"
  }, React.createElement("span", {
    className: "f-medium f-center"
  }, "H"), event.xPertHome.map((marks, index) =>
  React.createElement("div", {
    className: "retail-row-bet f-center",
    key: index
  }, React.createElement(ReactButtonBet, {
    label: marks[0],
    selected: true,
    size: "200"
  }), React.createElement(ReactButtonBet, {
    label: marks[1],
    selected: true,
    size: "200"
  })))), React.createElement("div", {
    className: "padding-h-2 padding-v-1 col-xs-3"
  }, React.createElement("span", {
    className: "f-medium f-center"
  }, "O"), event.xPertEven.map((marks, index) =>
  React.createElement("div", {
    className: "retail-row-bet f-center",
    key: index
  }, React.createElement(ReactButtonBet, {
    label: marks[0],
    selected: true,
    size: "200"
  }), React.createElement(ReactButtonBet, {
    label: marks[1],
    selected: true,
    size: "200"
  })))), React.createElement("div", {
    className: "padding-h-2 padding-v-1 col-xs-3"
  }, React.createElement("span", {
    className: "f-medium f-center"
  }, "B"), event.xPertAway.map((marks, index) =>
  React.createElement("div", {
    className: "retail-row-bet f-center",
    key: index
  }, React.createElement(ReactButtonBet, {
    label: marks[0],
    selected: true,
    size: "200"
  }), React.createElement(ReactButtonBet, {
    label: marks[1],
    selected: true,
    size: "200"
  })))))))));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.BombenXpertResult', BombenXpertResult);

 })(window);