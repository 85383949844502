(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/racing-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/racing-result.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  RacePickerItemLight
} = svs.racing;
const {
  useRace
} = svs.components.marketplaceShared.hooks;
const {
  ReactIcon: Icon
} = svs.ui;
const {
  RacingPresentation
} = svs.components.racing.racingPresentation;
const {
  useSelector
} = ReactRedux;
const renderEmptyView = () => React.createElement("div", {
  className: "loading-area padding"
}, React.createElement("div", {
  className: "qa-info-text fc-grey-400 padding f-center"
}, React.createElement(Icon, {
  icon: "info",
  size: "200"
}), React.createElement("span", {
  className: "margin-left-half"
}, "Vi kan inte visa raden just nu")));
const RacingResult = _ref => {
  let {
    activatedDrawId,
    wager
  } = _ref;
  const groupId = useSelector(state => state.EditorSession.groupId);
  const {
    race,
    meeting,
    draw
  } = useRace(activatedDrawId, groupId);
  if (race && meeting && draw) {
    return React.createElement("div", null, React.createElement(RacePickerItemLight, {
      className: "margin-bottom-1",
      meeting: meeting,
      raceSummary: _objectSpread(_objectSpread({}, race), {}, {
        draws: [draw]
      })
    }), wager && wager.bets && React.createElement(RacingPresentation, {
      fallbackComponent: renderEmptyView,
      gameType: wager.bets[0].subProductId,
      participants: race.participants,
      race: _objectSpread(_objectSpread({}, race), {}, {
        meeting
      }),
      silksForattedDate: meeting.silksFormattedDate,
      systemType: wager.bets[0].rxBoards[0].systemType[0],
      wager: wager.bets[0]
    }));
  }
  return renderEmptyView();
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.RacingResult', RacingResult);

 })(window);