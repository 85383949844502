(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-result-frame.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-result-frame.js');
"use strict";


const BombenResultFrame = _ref => {
  let {
    children,
    isPeriod,
    periodHeader
  } = _ref;
  return React.createElement("div", {
    "data-testid": "bomben-result-frame"
  }, isPeriod && React.createElement("div", {
    className: "padding-bottom-1 f-normal"
  }, periodHeader), React.createElement("div", {
    className: "retail-row-bomben-bets"
  }, children));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.BombenResultFrame', BombenResultFrame);

 })(window);