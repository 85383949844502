(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/tipsen-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/tipsen-result.js');
"use strict";


const {
  ReactButtonBet
} = svs.ui;


const defaultProps = {
  events: null,
  hasMSign: false,
  isReduceFree: false,
  mSigns: [],
  percentages: [],
  uSigns: []
};

const TipsenResult = _ref => {
  let {
    events,
    isReduceFree,
    hasMSign,
    percentages,
    uSigns,
    mSigns
  } = _ref;
  const _renderRow = (eventItem, eventIndex) => {
    const {
      eventNumber,
      eventDescription,
      participantType
    } = eventItem || {};
    const {
      percentage1,
      percentageX,
      percentage2
    } = percentages[eventIndex] || {};
    const eventComment = participantType === 'Player' ? 'Vem gör flest mål?' : null;
    return React.createElement("div", {
      className: "retail-tipsen-result-row",
      key: eventNumber || eventIndex
    }, React.createElement("div", {
      className: "event"
    }, React.createElement("div", null, "".concat(eventNumber, ". ").concat(eventDescription)), Boolean(eventComment) && React.createElement("div", {
      className: "event-comment"
    }, eventComment)), Boolean(isReduceFree) && React.createElement(React.Fragment, null, React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "".concat(percentage1, "%"),
      selected: percentage1 > 0,
      size: "200"
    })), React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "".concat(percentageX, "%"),
      selected: percentageX > 0,
      size: "200"
    })), React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "".concat(percentage2, "%"),
      selected: percentage2 > 0,
      size: "200"
    }))), !isReduceFree && React.createElement(React.Fragment, null, Boolean(hasMSign) && React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "M",
      selected: mSigns[eventIndex],
      size: "200"
    })), React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "1",
      selected: percentage1 > 0,
      size: "200",
      system: uSigns[eventIndex] === '1' ? 'U' : null
    })), React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "X",
      selected: percentageX > 0,
      size: "200",
      system: uSigns[eventIndex] === 'X' ? 'U' : null
    })), React.createElement("div", {
      className: "bet"
    }, React.createElement(ReactButtonBet, {
      label: "2",
      selected: percentage2 > 0,
      size: "200",
      system: uSigns[eventIndex] === '2' ? 'U' : null
    }))));
  };
  return React.createElement("div", {
    className: "retail-tipsen-result",
    "data-testid": "tipsen-result"
  }, React.createElement("div", {
    className: "retail-tipsen-result-header"
  }, React.createElement("div", {
    className: "event"
  }, "Matcher"), Boolean(!isReduceFree && hasMSign) && React.createElement("div", {
    className: "bet"
  }), React.createElement("div", {
    className: "bet"
  }, "1"), React.createElement("div", {
    className: "bet"
  }, "X"), React.createElement("div", {
    className: "bet"
  }, "2")), (events || []).map(_renderRow));
};
TipsenResult.defaultProps = defaultProps;
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.TipsenResult', TipsenResult);

 })(window);