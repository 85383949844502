(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/row-result-view.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/row-result-view.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  FulltraffResult,
  RacingResult,
  RowResults
} = svs.components.marketplaceRetailAdmin.rowResult;
const {
  games: gameSelectors
} = svs.components.marketplaceData.selectors;
const {
  products
} = svs.utils;
const RowResultView = _ref => {
  let {
    activatedDrawId
  } = _ref;
  const activatedDraw = useSelector(state => gameSelectors.getGameByActivatedDrawId(state, state.EditorSession.groupId, activatedDrawId));
  if (!activatedDraw) {
    return null;
  }
  if (activatedDraw.productId === products.productIds.FULLTRAFF) {
    return React.createElement(FulltraffResult, {
      activatedDrawId: activatedDrawId
    });
  }
  if (activatedDraw.productId === products.productIds.RACING) {
    return React.createElement(RacingResult, {
      activatedDrawId: activatedDrawId,
      wager: activatedDraw.wager
    });
  }
  return React.createElement(RowResults, {
    activatedDrawId: activatedDrawId
  });
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.RowResultView', RowResultView);

 })(window);