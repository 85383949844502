(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/bomben-marks.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/bomben-marks.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const logger = svs.core.log.getLogger('o2k:row-result');
const bombenMarks = (events, wager) => {
  if (typeof events === 'undefined' || typeof wager === 'undefined') {
    logger.error(new Error('bomben-marks: lack sufficient parameters'));
    return [];
  }
  const newEvents = events.map(event => {
    const newEvent = _objectSpread(_objectSpread({}, event), {}, {
      match1Marks: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        F: false
      },
      match2Marks: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        F: false
      },
      matchRData: {
        HOME: false,
        DRAW: false,
        AWAY: false
      }
    });
    return newEvent;
  });
  const marksPerGameBomben = events && wager && wager.bets[0].scoreBoards[0].boardData || [];
  marksPerGameBomben.forEach((marks, i) => {
    const eventIndex = Math.floor(i / 2);
    const matchIndex = i % 2;
    marks.forEach(mark => {
      if (matchIndex === 0) {
        newEvents[eventIndex].match1Marks[mark] = true;
      } else {
        newEvents[eventIndex].match2Marks[mark] = true;
      }
    });
  });
  const rData = events && wager && wager.bets[0].scoreBoards[0].rSysData || [];
  rData.forEach((rEvents, i) => {
    rEvents.forEach(rEvent => {
      newEvents[i].matchRData[rEvent] = true;
    });
  });
  return newEvents;
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.helpers.bombenMarks', bombenMarks);

 })(window);