(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/challenge-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/challenge-result.js');
"use strict";


const {
  ReactButtonBet
} = svs.ui;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const ChallengeResult = _ref => {
  let {
    events = [],
    percentages = []
  } = _ref;
  events.forEach(event => {
    var _event$outcomes;
    if (((_event$outcomes = event.outcomes) === null || _event$outcomes === void 0 ? void 0 : _event$outcomes.length) === 2) {
      event.outcomes.splice(1, 0, {
        description: '',
        outcomeNumber: -1
      });
    }
  });
  const getOutcomeLabel = _ref2 => {
    let {
      eventIndex,
      outcomeIndex
    } = _ref2;
    const currentEvent = events[eventIndex];
    const currentOutcome = currentEvent === null || currentEvent === void 0 ? void 0 : currentEvent.outcomes[outcomeIndex];
    if ((currentEvent === null || currentEvent === void 0 ? void 0 : currentEvent.eventTypeId) === EventTypeId.OVER_UNDER && currentOutcome.outcomeNumber !== -1) {
      return outcomeIndex === 0 ? 'Ö' : 'U';
    }
    return currentOutcome === null || currentOutcome === void 0 ? void 0 : currentOutcome.description;
  };
  return React.createElement("div", {
    className: "challenge-retail-row-events",
    "data-testid": "challenge-result"
  }, React.createElement("div", {
    className: "retail-row-header f-left f-medium"
  }, "Matcher"), events.map((event, eventIndex) => {
    var _percentages$eventInd, _percentages$eventInd2, _percentages$eventInd3;
    return React.createElement("li", {
      className: "event-holder",
      "data-testid": "challenge-event-holder",
      key: event.eventNumber || eventIndex
    }, React.createElement("div", {
      className: "event"
    }, React.createElement("div", {
      className: "event-number"
    }, "".concat(event.eventNumber, ".")), React.createElement("div", {
      className: "event-description"
    }, React.createElement("p", null, event.eventDescription), React.createElement("p", {
      className: "fc-grey-400 event-eventcomment"
    }, event.eventComment)), React.createElement("div", {
      className: "event-outcomes-holder",
      "data-testid": "challenge-outcome-holder"
    }, React.createElement("div", {
      className: "event-outcomes f-center"
    }, React.createElement(ReactButtonBet, {
      "data-testid": "challenge-outcome-btn",
      label: getOutcomeLabel({
        eventIndex,
        outcomeIndex: 0
      }),
      selected: ((_percentages$eventInd = percentages[eventIndex]) === null || _percentages$eventInd === void 0 ? void 0 : _percentages$eventInd.percentage1) !== 0,
      size: "300"
    }), React.createElement(ReactButtonBet, {
      "data-testid": "challenge-outcome-btn",
      label: getOutcomeLabel({
        eventIndex,
        outcomeIndex: 1
      }),
      selected: ((_percentages$eventInd2 = percentages[eventIndex]) === null || _percentages$eventInd2 === void 0 ? void 0 : _percentages$eventInd2.percentageX) !== 0,
      size: "300"
    }), React.createElement(ReactButtonBet, {
      "data-testid": "challenge-outcome-btn",
      label: getOutcomeLabel({
        eventIndex,
        outcomeIndex: 2
      }),
      selected: ((_percentages$eventInd3 = percentages[eventIndex]) === null || _percentages$eventInd3 === void 0 ? void 0 : _percentages$eventInd3.percentage2) !== 0,
      size: "300"
    })))));
  }));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.ChallengeResult', ChallengeResult);

 })(window);