(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/get-percentages.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/helpers/get-percentages.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const logger = svs.core.log.getLogger('o2k:row-result');
function getPercentages1X2PerRow(rows) {
  if (typeof rows === 'undefined') {
    logger.error(new Error('get percentages: no rows submitted'));
    return [];
  }
  return rows.reduce((acc, row) => {
    row.forEach((sign, index) => {
      acc[index] = _objectSpread({}, acc[index] || {
        amount1: 0,
        amountX: 0,
        amount2: 0,
        total: 0
      });
      switch (sign) {
        case '1':
          acc[index].amount1 += 1;
          break;
        case 'X':
          acc[index].amountX += 1;
          break;
        case '2':
          acc[index].amount2 += 1;
          break;
        default:
      }
      acc[index].total += 1;
    });
    return acc;
  }, []).map(data => ({
    percentage1: Math.round(data.amount1 / data.total * 100),
    percentageX: Math.round(data.amountX / data.total * 100),
    percentage2: Math.round(data.amount2 / data.total * 100)
  }));
}
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.helpers.getPercentages1X2PerRow', getPercentages1X2PerRow);

 })(window);