(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-regular-result.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/row-result/assets/javascripts/bomben-regular-result.js');
"use strict";


const {
  ReactButtonBet
} = svs.ui;
const {
  BombenEvent,
  BombenResultFrame
} = svs.components.marketplaceRetailAdmin.rowResult;
const BombenRegularResult = _ref => {
  let {
    isPeriod,
    periodHeader,
    bombenEventMarks
  } = _ref;
  return React.createElement(BombenResultFrame, {
    isPeriod: isPeriod,
    periodHeader: periodHeader
  }, bombenEventMarks.map(event => React.createElement(BombenEvent, {
    description: event.eventDescription,
    isCancelled: event.isCancelled,
    key: event.eventNumber
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "retail-row-bet f-center"
  }, Object.keys(event.match1Marks).map((key, index) => React.createElement(ReactButtonBet
  , {
    key: index,
    label: key,
    selected: event.match1Marks[key],
    size: "200"
  })), React.createElement(ReactButtonBet, {
    label: "H",
    selected: !event.matchRData.HOME,
    size: "200"
  })), React.createElement("div", {
    className: "retail-row-bet f-center align-items-right"
  }, React.createElement(ReactButtonBet, {
    label: "O",
    selected: !event.matchRData.DRAW,
    size: "200"
  })), React.createElement("div", {
    className: "retail-row-header"
  }), React.createElement("div", {
    className: "retail-row-bet f-center"
  }, Object.keys(event.match2Marks).map((key, index) => React.createElement(ReactButtonBet
  , {
    key: index,
    label: key,
    selected: event.match2Marks[key],
    size: "200"
  })), React.createElement(ReactButtonBet, {
    label: "B",
    selected: !event.matchRData.AWAY,
    size: "200"
  }))))));
};
setGlobal('svs.components.marketplaceRetailAdmin.rowResult.BombenRegularResult', BombenRegularResult);

 })(window);